<template>
  <div class="settings" style="min-width: 1170px">
    <div class="forfreelancer-header">
      <video autoplay muted loop poster="https://gcdn.bionluk.com/site/mainpage/freelancer_olmak.jpg" id="bgvid">
        <source src="https://gcdn.bionluk.com/site/mainpage/freelancer_olmak.mp4" type="video/mp4" />
      </video>
      <div style="margin-top: 136px;">
        <p class="header-title">
          İstediğin Gibi Çalış!
        </p>
        <p class="header-description">
          Freelancer topluluğumuza katılarak, yeteneklerini sergilersin. Bionluk da sana iş bulur.

        </p>
          <div class="header-button">
            <router-link class="header-button nounderlinehover" to="/become_a_freelancer">
              <p>
                Freelancer Ol!
              </p>
            </router-link>
          </div>

      </div>
      <div class="header-bottom-div">
        <div class="header-info" v-for="info in headerInfos">
          <p class="header-info-title">{{info.title}}</p>
          <p class="header-info-info">{{info.info}}</p>
        </div>
      </div>
    </div>

    <div class="forfreelancer-cards-body">
      <div class="cards-main">
        <div class="card-div"
             v-for="card in cardsArray"
             @click="openCardModal(card)"
             :style="`background: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${card.imageUrl});   background-position: center; background-size: cover`">
          <div class="card-bottom">
            <p class="card-title">{{card.text}}</p>
            <p class="card-username">{{card.username}}</p>
            <img src="https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_solok.svg"/>
          </div>
        </div>
        <div class="last-card">
          <p>Senin yeteneğin ne?</p>
          <div>
            <router-link class="nounderlinehover" style="color: #FFF" to="/become_a_freelancer">
              Hemen Başla
            </router-link>

          </div>
        </div>
      </div>
      <div class="how-works-main">
        <p class="how-works-title">Nasıl çalışır?</p>
        <div style="display: flex; margin-top: 32px;">
          <div class="work-desc-main" v-for="work in howWorksArray">
            <img :src="work.imageUrl"/>
            <p class="work-title">{{work.title}}</p>
            <p class="work-description">{{work.description}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="slider-body">

      <p style="font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: #333333;">Kimlerle çalışabilirsin?</p>
      <div class="hiddendiv"></div>
      <div style="display: flex; margin-top: 20px; margin-bottom: 20px; align-self: center; align-items: center; justify-content: center; text-align: center;">
        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/isbankasi.png">
        <img class="brand-image" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/yemeksepeti.png">

        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/gures.png">

        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/trendyol.png">
        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/onedio.png">

        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/mutlubiev.png">

      </div>
      <div style="display: flex;  margin-top: 20px; margin-bottom: 20px; align-self: center; align-items: center; justify-content: center; text-align: center;">
        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/sabancivakfi.png">
        <img class="brand-image" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/iyzico.png">

        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/oyveotesi.png">

        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/kollektifhouse.png">
        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/marmaray.png">

        <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/insider.png">
      </div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv10"></div>

      <carousel :navigationEnabled="false" :perPage="1" class="slider-main">
        <slide v-for="(slider, index) in sliderData" :key="index" class="slider-inner">
          <img class="slider-user-image" :src="slider.imageUrl"/>
          <div class="slider-user-info">
            <p class="slider-user-text">“{{slider.text}}”</p>
            <p class="slider-user-username">{{slider.username}}</p>
            <p class="slider-user-title">{{slider.title}}</p>
          </div>
        </slide>
      </carousel>
    </div>
    <div v-if="blogViews.length" class="mainpage-marketplace-body" style="min-width: 1170px; background-color: #f4f5f7">
      <h1>
        <span style="font-weight: 600;">Bi Blog</span>
        <br>
        <span style="margin-top: 17px; font-size: 22px; font-weight: 300; color: #8b95a1;">
        En güncel haberler ve ipuçları için blogumuza göz at.
        </span>
      </h1>
      <div style="width: 1170px; margin: 0 auto 60px; ">
        <div style="display: flex; justify-content: space-between">
          <BlogView v-for="(blogView, index) in blogViews" :blog-view="blogView" :key="blogView.pathToGo + index" />
        </div>
      </div>

      <div class="mainpage-learnmore-div">
        <router-link style="text-decoration: none;" to="/blog">Tüm Yazılar</router-link>
      </div>
      <div class="hiddendiv>"></div>
      <div class="hiddendiv>"></div>

    </div>
    <div class="faq-body">
      <p class="faq-title">Sıkça sorulan sorular</p>
      <div class="faq-main">
        <div class="faq-array-div" v-for="faq in faqs">
          <div v-if="!faq.open" class="faq-close-div" @click="reverseFaq(faq)">
            <p>{{faq.question}}</p>
            <img src="https://gcdn.bionluk.com/site/icon/tansel_f_o_altok.svg"/>
          </div>
          <div v-if="faq.open" class="faq-open-div" @click="reverseFaq(faq)">
            <div style="display: flex; justify-content: space-between;">
              <p class="faq-open-question">{{faq.question}}</p>
              <img class="faq-open-image" src="https://gcdn.bionluk.com/site/icon/tansel_f_o_ustok.svg"/>
            </div>
            <p class="faq-open-answer">{{faq.answer}}</p>
          </div>
        </div>
      </div>
      <div class="faq-bottom">
        <p>Senin yeteneğin ne?</p>
        <div>
          <router-link class="nounderlinehover" style="color: #FFF" to="/become_a_freelancer">
            Hemen Başla
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bindCodeToHtml, generateCodeForFaq } from '@/utils/schema'
import Api from "@/utils/request";
import BlogView from "@/pages/components/BlogView";

  export default {
    name: "src-pages-body-main-for-freelancers-for-freelancers",
    components: {BlogView},
    data() {
      return {
        blogViews: [],
        cardsArray: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/ozgur_k.jpg',
            username: 'ozgurbabal',
            text: 'Ben, profesyonel İngilizce/Türkçe seslendirme yaparım.',
            title: 'Seslendirmen',
            modalInfo: {
              title: 'Ses & Müzik',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/ozgur_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 132.532
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 49.412
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 62
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "50TL - 13.500TL"
                }
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/alina_k.jpg',
            username: 'alina_S',
            text: 'Ben, Rusça/Ukraynaca/İngilizce çevirilerinizi yapabilirim.',
            title: 'Çevirmen',
            modalInfo: {
              title: 'Yazı & Çeviri',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/alina_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 36.765
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 52.342
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 64
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "10TL - 8.500TL"
                },
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/safak_k.jpg',
            username: 'safaktozar',
            text: 'Ben, sizin için profesyonel bir logo tasarlarım.',
            title: 'Tasarımcı',
            modalInfo: {
              title: 'Grafik & Tasarım',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/safak_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 236.841
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 78.211
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 72
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "10TL - 22.500TL"
                },
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/evren_k.jpg',
            username: 'evrenerarslan',
            text: 'Ben, Excel\'de güçlü analizler yaparım, sorunlarınızı çözerim.',
            title: 'Tasarimci',
            modalInfo: {
              title: 'İş & Yönetim',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/evren_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 16.765
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 9.844
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 38
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "20TL - 4.200TL"
                },
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/idris_k.jpg',
            username: 'idrisyildiz',
            text: 'Ben, profesyonel iOS/Android mobil uygulamalar geliştiririm.',
            title: 'Mobil Yazılımcı',
            modalInfo: {
              title: 'Yazılım & Teknoloji',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/idris_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 31.765
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 28.412
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 53
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "300 - 35.000TL"
                },
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/idil_k.jpg',
            username: 'idil87',
            text: 'Ben, sosyal medya hesaplarınızı titiz bir şekilde yönetirim.',
            title: 'Sosyal Medya Uzmanı',
            modalInfo: {
              title: 'İnternet Reklamcılığı',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/idil_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 196.324
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 65.332
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 69
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "10TL - 9.500TL"
                },
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/puppet_k.jpg',
            username: 'puppetmaster',
            text: 'Ben, tanıtımlarınız için whiteboard animasyonlar yaparım.',
            title: '2D Animasyon Uzmanı',
            modalInfo: {
              title: 'Video & Animasyon',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/puppet_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 76.765
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 62.412
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 48
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "100 - 7.000TL"
                },
              ]
            }

          }
        ],
        howWorksArray: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_3.png',
            title: 'İş ilanı oluştur',
            description: 'Uzman olduğun kategorilerde başvuru yaptıktan sonra sıra ilan oluşturmaya geldi. İlanın yayınlandığı andan itibaren satış yapmaya hazırsın!'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_2.png',
            title: 'İşi teslim et',
            description: 'Artık alıcılar senden hizmet almaya başlayabilir. Aldığın siparişini tamamla, puan ve olumlu yorum toplamaya devam et. '
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_1.png',
            title: 'Ödemeni al',
            description: 'Teslim ettiğin sipariş onaylandıktan sonra ödemen hemen yolda. Bionluk’ta verdiğin hizmetin karşılığını her zaman zamanında alırsın.'
          },
        ],
        headerInfos: [
          {
            title: 'Her 6 Dakikada',
            info: '1 yeni sipariş'
          },
          {
            title: 'Tamamlanan Sipariş',
            info: '+450.000 adet'
          },
          {
            title: 'Fiyat Aralığı',
            info: '10TL - 22.600TL'
          },
        ],
        faqs: [
          {
            open: false,
            question: 'Bionluk’ta ne iş yapabilirim?',
            answer: 'Freelance çalışma sana yaratıcılık sunar. Bionluk\'ta freelancer olmak istiyorsan; yasalara ve kullanıcı sözleşmesine aykırı olmadığı sürece, çeviri, metin yazarlığı, grafik tasarım, web sitesi tasarımı gibi, 7 ana, 60\'dan fazla alt kategoride hizmet verebilir ve satış yapmaya başlayabilirsin.'
          },
          {
            open: false,
            question: 'Freelancer olarak ne kadar para kazanabilirim?',
            answer: 'Freelancer  olarak Yazı & Çeviri, Sosyal Medya Yönetimi, Logo Tasarımı gibi yetenekli olduğun farklı kategorilerde dilediğin kadar para kazanabilisin. Ne  kadar kazanacağın tamamen sana bağlı; daha çok sipariş ve yüksek puan aldıkça kazanma şansın da  artabilir.'
          },
          {
            open: false,
            question: 'Bionluk freelancer üyeliği ne kadar?',
            answer: 'Bionluk’ta tek seferliğe mahsus olmak üzere 69.90TL ödeyerek freelancer hesabı oluşturabilirsin. Topluluk üyelerine daha verimli bir alan sunmak üzere  Bionluk.com siparişlerin üzerinden %20 hizmet bedeli alır, toplam tutarın %80’i ise sana kalır. Yani sipariş alıp para kazanana kadar üyelik ücreti dışında herhangi bir ödeme yapmana gerek kalmaz.'
          },
          {
            open: false,
            question: 'Tam zamanlı işim varken de freelance olarak çalışabilir miyim?',
            answer: 'Freelancer olmak genellikle "ek iş" gibi görülse de pazaryeri dinamiklerini anladıktan ve müşteri portföyünü oluşturduktan sonra "tam zamanlı bir freelancer" olarak çalışabilir ve ne zaman, nasıl çalışacağına tamamen sen karar verebilirsin.'
          },
          {
            open: false,
            question: 'Hizmet ücretleri nasıl belirleniyor?',
            answer: 'Bionluk’ta hizmetlerinin bedelini sen belirlersin. Yine de kafan karışıyorsa hizmet verdiğin kategoride diğer ilanların fiyatlandırmasını kontrol etmeni öneririz.'
          },
          {
            open: false,
            question: 'Bionluk’ta ilan fiyatları nasıl belirlenir?',
            answer: 'Bionluk\'ta satış yapmak için fiyatlarını tamamen sen belirlersin. Yine de kafan karışıyorsa hizmet verdiğin kategoride diğer ilanların fiyatlandırmasını kontrol etmeni öneriyoruz.'
          },
          {
            open: false,
            question: 'Bionluk’ta freelancer olarak ödememi nasıl alırım?',
            answer: 'Siparişini teslim ettikten sonra alıcı işi onaylar ve ödemen anında Bionluk Bakiyene aktarılır. Bakiyenden para çekme talimatı verdiğinde, 2-5 iş günü içinde sipariş tutarı Bionluk\'da kayıtlı IBAN numarana aktarılır.'
          }
        ],
        sliderData: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_1.jpg',
            text: 'Volt için hazırladığımız video serisinin seslendirmesini nasıl yaptıracağımızı konuşurken biri Bionluk\'u önerdi. Örnek seslendirmeleri dinledik, istediğimiz bir sesi bulduk, metinleri paylaştık ve bir gün sonra ses kayıtlarını aldık. :)',
            username: 'Seda Taner',
            title: 'Volt Turkey, Marketing Manager'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_2.jpg',
            text: 'Yuvako.com olarak hızlı ve yetenekli freelancer arayışımızda Bionluk gibi bir platformun varlığı bize çok yardımcı oldu. İçimize sinen güzel bir proje ortaya çıkardık. Teşekkürler Bionluk!',
            username: 'Can Erenberk',
            title: 'Co-founder, Yuvako.com'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_4.jpg',
            text: 'Garajyeri olarak araç sahipleri için sistemi anlatan videolar hazırlamamız gerekiyordu. Bionluk üzerinden metinleri teslim ettik. 1 hafta sonra videolar sitemizde yayındaydı. Çok temiz iş. Hizmet verenlerin iletişimi mükemmel.',
            username: 'Arda Aşkın',
            title: 'Kurucu, Garajyeri.com'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_5.jpg',
            text: 'Acil olarak beliren bir seslendirme projemiz için arayışımız vardı. O noktada Bionluk.com’a göz atarak, uygun bütçeyle bir seslendirme sanatçısı bulduk. Hem bütçe hem hız anlamında çok iyi sonuç aldık. Çok teşekkürler Bionluk.com!',
            username: 'Çağdaş Önen',
            title: 'Pazarlama Müdürü, iyzico'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_3.jpg',
            text: 'Ucuz olduğu için Bionluk`ta alışveriş yapmıyorduk. Ama ekstra seçeneklerle almak istediğimiz kalitede bir hizmet alabileceğimizi farkettik. Bionluk`ta ihtiyacımız olan işleri kolay ve hızlı bir şekilde yaptırabiliyoruz',
            username: 'Güneş Gülbiter',
            title: 'Sosyal Medya Direktörü, Scorp App'
          }
        ]
      }
    },
    methods: {
      reverseFaq(faq) {
        faq.open = !faq.open;
      },
      openCardModal(card) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FOR_FREELANCERS, info: card});
      }
    },
    created() {
      Api.get('/blog-views/freelancer-olmak')
        .then(({ data }) => {
          this.blogViews = data
        })
      bindCodeToHtml(JSON.stringify(generateCodeForFaq(this.faqs)))
    }
  }
</script>

<style scoped lang="scss">

  .forfreelancer-header {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0 auto;
    height: 620px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-title {
      font-size: 55px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }

    video#bgvid {
      position:absolute;
      top:50%;
      left:50%;
      width:100%;
      height: auto;
      z-index:-100;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      background: url(https://gcdn.bionluk.com/site/mainpage/freelancer_olmak.jpg) no-repeat;
      background-size: cover;
    }
    video {
      display: inline-block;
      vertical-align: baseline;
    }

    .header-description {
      margin-top: 15px;
      font-size: 18px;
      line-height: 1.22;
      text-align: center;
      color: #ffffff;
    }

    .header-button {
      width: 220px;
      height: 60px;
      margin: 0 auto;
      margin-top: 60px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 57px;
      cursor: pointer;
    }

    .header-button:hover {
      width: 220px;
      height: 60px;
      margin: 0 auto;
      margin-top: 60px;
      border-radius: 2px;
      background-color: #FF2D55;
      text-underline: none;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 57px;
      cursor: pointer;
    }

    .header-bottom-div {
      width: 100%;
      height: 120px;
      opacity: 0.8;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .header-info {
    display: flex;
    flex-direction: column;
    margin-left: 140px;
    &:first-child {
      margin-left: 0;
    }
    .header-info-title {
      font-size: 18px;
      text-align: center;
      color: #ffffff;

    }

    .header-info-info {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      margin-top: 12px;
    }
  }

  .forfreelancer-cards-body {
    min-width: 1170px;
    padding-bottom: 57px;
    padding-top: 57px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .cards-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1141px;
  }

  .card-div {
    width: 265px;
    height: 340px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-position: center;
    background-size: 265px 340px;
    display: flex;
    cursor: pointer;
    &:nth-child(4) {
      margin-right: 0;
    }

    &:nth-child(n+5) {
      margin-top: 30px;
    }
    margin-right: 27px;
    .card-bottom {
      margin-top: auto;
      padding-left: 18px;

      .card-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        color: #ffffff;
        width: 228.7px;
      }

      .card-username {
        margin-top: 11px;
        font-size: 13px;
        line-height: 1.85;
        color: #ffffff;
      }

      img {
        margin-top: 13px;
        width: 23.7px;
        height: 19px;
        margin-bottom: 14px;
      }
    }
  }

  .last-card {
    width: 265px;
    height: 340px;
    margin-top: 30px;
    background: url('https://gcdn.bionluk.com/site/general/tansel_freelancer_bg4.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      width: 162px;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.27;
      text-align: center;
      color: #333333;
    }

    div {
      margin-top: 12px;
      width: 200px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .how-works-main {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 1170px;
    .how-works-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
    }
  }

  .work-desc-main {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    align-items: center;
    &:nth-child(3) {
      margin-right: 0;
    }

    img {
      width: 89px;
      height: 89px;
    }
    .work-title {
      margin-top: 3px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }

    .work-description {
      margin-top: 17px;
      width: 300px;
      height: 94px;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #8b95a1;
    }

  }

  .faq-body {
    min-width: 1170px;
    padding-bottom: 53px;
    padding-top: 52px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;

    .faq-title {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: #333333;
    }
  }

  .faq-main {
    display: flex;
    flex-direction: column;
    margin-top: 43px;
    .faq-array-div {
      width: 944px;
    }
  }

  .faq-close-div {
    border-top: solid 1px #e0e0e0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 18px 23px 20px;
    cursor: pointer;

    p {
      font-size: 20px;
      text-align: left;
      color: #2d3640;
    }
  }

  .faq-open-div {
    border-top: solid 1px #e0e0e0;
    padding: 20px;
    width: 904px;
    height: 169px;
    background-color: #ffffff;
    cursor: pointer;
    .faq-open-question {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      color: #2d3640;
    }

    .faq-open-answer {
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
      color: #8b95a1;
      margin-top: 26px;
    }
  }

  .faq-bottom {
    width: 1140px;
    height: 201px;
    margin-top: 52px;
    background: url('https://gcdn.bionluk.com/site/icon/freelancer_olmak_bg_alt.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      margin-right: 30px;
    }

    div {
      width: 200px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .slider-body {
    min-width: 1170px;
    padding-top: 68px;
    padding-bottom: 99px;
    .slider-main {
      width: 810px;
      margin: 0 auto;
    }
  }

  .slider-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 810px;
    .slider-user-image {
      width: 230px;
      height: 230px;
      border-radius: 50%;
    }

    .slider-user-info {
      margin-left: 68px;
      display: flex;
      flex-direction: column;
      .slider-user-text {
        max-height: 164px;
        width: 501px;
        font-size: 20px;
        font-style: italic;
        line-height: 1.4;
        text-align: left;
        color: #8b95a1;
      }

      .slider-user-username {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #333333;
      }

      .slider-user-title {
        margin-top: 7px;
        font-size: 12px;
        text-align: left;
        color: #8b95a1;
      }
    }

    .become_a_freelancerlink :hover{
      background-color: red!important;
      color: black;
    }


  }


  .brand-image{
    width: 100px; height: 100px; margin-right: 30px; border-radius: 6px; border: 1px solid #e1e1e1;
  }

  .mainpage-marketplace-body {
    background-color: #f4f5f7;
    padding-top: 52px;
    padding-bottom: 60px;
  }

  .mainpage-marketplace-body h1 {
    font-size: 42px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
    padding-bottom: 20px;
  }

  .mainpage-learnmore-div {
    width: 220px;
    height: 50px;
    border-radius: 2px;

    border: 1px solid rgba(75, 79, 82, .2);

    margin: auto;
  }

  .mainpage-learnmore-div:hover {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    margin: auto;
  }

  .mainpage-learnmore-div a {
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 54, 64);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
  }
</style>
